import {
  CUS_HEADER,
  WEB_AUTH_ALG,
  WEB_AUTH_MER_TXN_ID,
  WEB_AUTH_MID,
  WEB_AUTH_URL,
} from "../utils/consts";
import {
  init,
  authenticateUser as webauth_auth,
  registerUser as webauth_register,
} from "./passwordless";

// here fCB => failure callback function, sCB => successcallback
export const registerUser = (
  username: string,
  fCB: Function,
  sCB: Function
) => {
  init(WEB_AUTH_URL, WEB_AUTH_ALG);
  webauth_register(
    username,
    WEB_AUTH_MID,
    WEB_AUTH_MER_TXN_ID,
    CUS_HEADER,
    fCB,
    sCB
  );
};

export const authenticateUser = (
  username: string,
  userVerificationValue: string,
  fCB: Function,
  sCB: Function
) => {
  init(WEB_AUTH_URL, WEB_AUTH_ALG);
  webauth_auth(
    username,
    userVerificationValue,
    WEB_AUTH_MID,
    WEB_AUTH_MER_TXN_ID,
    CUS_HEADER,
    fCB,
    sCB
  );
};
